<template>
  <div class="p-2 bg-white">
    <p
      class="text-sm px-1 font-semibold text-a-neutral-lightest py-2"
    >
      {{ listTitle }}
    </p>
    <p
      v-for="(
        suggestion, index
      ) in suggestions"
      :key="index"
      class="w-full px-2 py-1 cursor-pointer text-sm hover:bg-a-secondary-lightest hover:text-a-neutral-darkest"
      @click="
        $emit(
          'suggestionSelected',
          suggestion
        )
      "
      v-html="
        highlightSearchQueryFromText(
          suggestion[
            highlightingFieldName
          ]
        )
      "
    ></p>
  </div>
</template>

<script setup>
const props = defineProps({
  suggestions: {
    type: Array,
    required: true,
  },
  highlightingFieldName: {
    type: String,
    required: true,
  },
  currentSearchQuery: {
    type: String,
    required: true,
  },
  listTitle: {
    type: String,
    default: "Matches",
    required: false,
  },
});

const emit = defineEmits(["suggestionSelected"]);

function highlightSearchQueryFromText(
  text
) {
  let searchValues =
    props.currentSearchQuery
      .trim()
      .split(" ");
  return highlightAll(
      text,
      searchValues
  );
}
function highlightAll(
  initialString,
  searchValues
) {
  let values = searchValues.join("|");
  if (values.trim() !== "") {
    return initialString.replace(
      new RegExp(values, "gi"),
      function (x) {
        return (
          "<strong class='text-a-secondary'>" +
          x +
          "</strong>"
        );
      }
    );
  }
}
</script>

<style></style>
